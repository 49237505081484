<!--盘点详情-->
<template>
  <div class="table-container" style="margin-top: -12px">
    <div class="base-wrapper">
      <el-row
        v-if="
          $route.path !== '/audit-process/audit-manage/index' &&
          !$route.query.noReturn
        "
      >
        <el-page-header
          title="返回"
          @back="goBack()"
          content="盘点详情"
        ></el-page-header>
      </el-row>
      <h3 class="mb20">基本信息</h3>
      <StockCheckEditBaseInfo :detail="detail" />
    </div>
    <div class="detail-table">
      <h3 class="mt30 mb20 inline-block mr20">盘点产品</h3>
      <span>总盘点数量:</span>

      <span class="mr20">
        {{
          detail.takingTotalNum || detail.takingTotalNum === 0
            ? detail.takingTotalNum
            : '-'
        }}
      </span>
      <span>总差异数量:</span>
      <span
        :style="
          detail.takingDiff > 0
            ? 'color:green'
            : detail.takingDiff < 0
            ? 'color:red'
            : ''
        "
      >
        {{
          detail.takingDiff || detail.takingDiff === 0 ? detail.takingDiff : '-'
        }}
      </span>
    </div>

    <el-table
      border
      striped
      :data="tableData"
      v-if="detail.status == 1"
      ref="proTable"
      :height="tableHeight"
    >
      <el-table-column
        align="center"
        label="产品图片"
        prop="productName"
        fixed="left"
      >
        <template slot-scope="scope">
          <erp-image :imgs="scope.row.productImage"></erp-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品编码"
        prop="skuId"
        fixed="left"
      ></el-table-column>
      <el-table-column
        align="center"
        label="产品名称"
        prop="productName"
        fixed="left"
      ></el-table-column>
      <el-table-column
        align="center"
        label="英文规格"
        prop="productSpec"
        fixed="left"
      >
        <template slot-scope="scope">
          {{ $hasChineseChar(scope.row.productSpec) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="中文规格"
        prop="productSpecCn"
        fixed="left"
      >
        <template slot-scope="scope">
          {{ scope.row.productSpecCn || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品类别"
        prop="productType"
      ></el-table-column>
      <el-table-column
        align="center"
        label="库位号"
        prop="fullLocationNumber"
      ></el-table-column>
      <el-table-column align="center" label="是否可用库存" prop="usable">
        <template slot-scope="scope">
          {{ scope.row.usable == 1 ? '是' : '否' }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="建单库位数"
        prop="num"
      ></el-table-column>
      <el-table-column
        align="center"
        label="可用量"
        prop="enableNum"
        width="120"
      >
        <template slot="header">
          <div>
            <span>可用库位数</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="不可用量"
        prop="disableNum"
        width="140"
      >
        <template slot="header">
          <div>
            <span>不可用库位数</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">该库位不可用库存。</div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="盘点数量"
        prop="takingNum"
      ></el-table-column>
      <el-table-column align="center" label="差异数量" prop="takingDiff">
        <template slot-scope="scope">
          <span
            :style="
              scope.row.takingDiff > 0
                ? 'color:green'
                : scope.row.takingDiff < 0
                ? 'color:red'
                : ''
            "
          >
            {{ scope.row.takingDiff }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="备注"
        prop="remark"
        width="220px"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <!-- 非未盘点其他状态 -->
    <div v-else>
      <el-form>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :span-method="objectSpanMethod"
          ref="proTable"
          :height="tableHeight"
        >
          <el-table-column align="center" label="产品图片" fixed="left">
            <template slot-scope="scope">
              <erp-image :imgs="scope.row.productImage"></erp-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品编码"
            prop="skuId"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            label="产品名称"
            prop="productName"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            label="英文规格"
            prop="productSpec"
            fixed="left"
          >
            <template slot-scope="scope">
              {{ $hasChineseChar(scope.row.productSpec) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="中文规格"
            prop="productSpecCn"
            fixed="left"
          >
            <template slot-scope="scope">
              {{ scope.row.productSpecCn || '--' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品类别"
            prop="productType"
          ></el-table-column>
          <el-table-column
            align="center"
            label="是否可用库存"
            prop="usable"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.usable == 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="库位号"
            prop="fullLocationNumber"
          ></el-table-column>
          <el-table-column
            align="center"
            label="建单库位数"
            prop="num"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            label="可用量"
            prop="enableNum"
            width="120"
          >
            <template slot="header">
              <div>
                <span>可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="不可用量"
            prop="disableNum"
            width="140"
          >
            <template slot="header">
              <div>
                <span>不可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">该库位不可用库存。</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="盘点数量"
            label-class-name="required-th"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.takingNum || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="差异数量" prop="takingDiff">
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.takingDiff > 0
                    ? 'color:green'
                    : scope.row.takingDiff < 0
                    ? 'color:red'
                    : ''
                "
              >
                {{ scope.row.takingDiff }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="备注"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.remark || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="实际出库/入库数量"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ abs(scope.row.takingDiff) }}
            </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip width="180">
            <template slot="header">
              <div>
                <span>入库批次</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="根据入库时间从小到大排列，最先入库批次在最上面。"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="lot-wrapper" v-if="scope.row.takingDiff < 0">
                {{ scope.row.stockLotNo }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="批次出库/入库数量"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">
              {{
                scope.row.takingDiff > 0
                  ? scope.row.takingDiff
                  : scope.row.lotNumber
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            width="180"
            prop="price"
          >
            <template slot="header">
              <div>
                <span>出库/入库单价</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    1. 若盘亏则带出对应批次的单价，不可修改；
                    <br />
                    2.
                    若盘盈且该产品在该仓库有库存，则取该产品在当前仓库最低单价；
                    <br />
                    3.
                    若盘盈且该产品在该仓库无库存，则取该产品在当前仓库最近一次出库单价。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="出库/入库成本"
            show-overflow-tooltip
            width="130"
          >
            <template slot-scope="scope">
              {{ scope.row.totalPrice || '--' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="采购单号"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">
              <span
                class="page-link-noline"
                v-if="scope.row.purchaseNumber"
                @click="goTopurchaseDetail(scope.row.purchaseNumber)"
              >
                {{ scope.row.purchaseNumber }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="采购开发"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.buyer || '-' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="出库/入库备注"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="500"
                show-word-limit
                v-model="scope.row.amendRemark"
                disabled
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <!-- 分页 -->
    <!-- 分页 -->
    <el-pagination
      background
      layout="prev, pager, next,total"
      :total="total"
      :current-page.sync="pageNo"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <div class="remark mt20">
      <p style="display: flex">
        <em style="width: 85px; display: block; white-space: nowrap">
          盈亏说明：
        </em>
        <span>{{ detail.takingResultDesc || '--' }}</span>
      </p>
    </div>
    <LocDetail
      :dialogVisible.sync="dialogVisible"
      :sku="detailSku"
      :stockTakingId="stockTakingId"
    />
  </div>
</template>
<script>
  import { mixin } from './help/mixins'
  import LocDetail from './components/loc-detail'
  import { StoreCheck } from '@/core'
  import StockCheckEditBaseInfo from './components/stock-check-edit-base-info'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import { getRemainingHeight } from 'kits'
  const mergeColumns = [
    '实际出库/入库数量',
    '产品图片',
    '产品编码',
    '产品名称',
    '规格',
    '产品类别',
    '是否可用库存',
    '库位号',
    '建单库位数',
    '可用量',
    '不可用量',
    '盘点数量',
    '差异数量',
    '备注',
  ]

  export default {
    name: 'StockCheckDetail',
    components: { LocDetail, StockCheckEditBaseInfo },
    mixins: [mixin],
    props: {
      detailId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        detail: {
          //详情
          wharsehouseName: '', //盘点仓库
          testuser: '', //盘点人
          creator: '', //创建人
          remark: '--', //备注
          products: [], // 盘点产品
        },
        currentRow: {}, //当前选中行
        warehouseId: '', //仓库id
        showType: '', // 类型
        dialogVisible: false, //弹窗控制
        detailSku: '', //sku
        stockTakingId: '', //盘点id
        tableHeight: 300, //表格高度
        spanArr: [], //合并参数
        pos: 0, //合并参数
        total: 0, //总页码
        pageSize: 200, //分页数量
        pageNo: 1, //第几页
        tableData: [],
        initTableData: [], //初始数据
      }
    },

    computed: {
      interactor() {
        //接口
        if (this.detailId) {
          return 'getDetailById'
        }
        return this.$route.query.showType == 'id'
          ? 'getDetailById'
          : 'getDetailBySku'
      },
    },
    created() {
      this.stockTakingId = this.$route.query.stockTakingId || this.detailId
      this.showType = this.$route.query.showType || 'id'
      this.getDetail()
    },
    mounted() {
      setTimeout(() => {
        this.getTableHeight()
      }, 200)
    },
    methods: {
      getTableHeight() {
        try {
          let heigth = getRemainingHeight(
            ['base-wrapper', 'detail-table', 'el-pagination', 'remark'],
            '',
            170
          )

          this.tableHeight = heigth
        } catch (err) {
          this.tableHeight = 300
        }
      },
      // 跳转采购订单详情
      goTopurchaseDetail(purchaseOrderNumber) {
        navigateTo(this, {
          pageType: PAGE_TYPES.purchaseOrderDetail,
          orderNumber: purchaseOrderNumber,
          roleJudge: false,
        })
      },
      abs(val) {
        if (typeof val === 'number') {
          return Math.abs(val)
        }
        return '-'
      },

      getPaginationData() {
        let initTableData = this.initTableData,
          pageNo = this.pageNo,
          pageSize = this.pageSize
        // 确保页码不小于1
        pageNo = Math.max(pageNo, 1)
        // 计算起始索引
        const startIndex = (pageNo - 1) * pageSize
        // 计算结束索引，注意JavaScript数组索引是从0开始的
        const endIndex = startIndex + pageSize

        // 获取指定页的数据
        const tableData = initTableData.slice(startIndex, endIndex)

        return tableData
      },
      /**
       * 合并单元格objectSpanMethod
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
      /**
       * 合并单元格getSpanArr
       */
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 添加orderProductUniqueId
            if (
              list[i].uniqueKey &&
              list[i - 1].uniqueKey &&
              `${list[i].uniqueKey}` === `${list[i - 1].uniqueKey}`
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      handleCurrentChange(val) {
        this.pageNo = val
        this.getTableData()
      },
      formatTableData(list, field = 'amends') {
        let tableData = []
        for (let i = 0; i < list.length; i++) {
          let item = list[i]
          if (item[field] && item[field].length !== 0) {
            let uniqueKey = Math.random()
            item[field].forEach((lotItem) => {
              tableData.push({
                ...lotItem, //放在前面避免num属性覆盖
                amendNum: lotItem.num,
                amendRemark: lotItem.remark,
                ...item,
                uniqueKey, // 添加唯一key
                num: item.num,
                stockTakingId: item.stockTakingId,
                stockTakingProductId: item.stockTakingProductId,
                lotNumber: lotItem.num,
              })
            })
          } else {
            tableData.push({
              ...item,
            })
          }
        }

        return tableData
      },
      getTableData() {
        if (this.detail.status != 1) {
          this.spanArr = [] //合并参数
          this.pos = 0 //合并参数
          //非未盘点状态
          let tableData = this.getPaginationData()
          this.tableData = this.formatTableData(tableData)
          this.getSpanArr(this.tableData)
        } else {
          this.tableData = this.getPaginationData()
        }
      },
      // 获取详情
      async getDetail() {
        let res = await StoreCheck[this.interactor](this.stockTakingId)
        if (res?.code === '000000') {
          this.initTableData = res?.data?.takingProductVOList || []
          if (res.data.takingProductVOList) {
            delete res.data.takingProductVOList
          }
          this.detail = res.data
          this.total = this.initTableData.length
          this.getTableData()
        }
      },

      // 关闭
      onClose() {
        this.locParams.show = false
      },
      // 展示库位
      addLoc(row) {
        this.locs = row.warehouseOutProductLocationVOList || []
        if (this.locs.length === 0) {
          return this.$message.warning('没有出库库位数据')
        }
        this.showOutLocDialog = true
      },

      // 查看子产品
      viewChildProduct(val) {
        this.warehouseId = this.detail.warehouseId
        this.$refs.stockBom.showDialog()
        this.$refs.stockBom.gridData = val.childProduct
      },
      // 展示库位
      showLoc(index) {
        let row = this.detailTableData[index]
        this.$refs['loc-detail'].show()
        this.currentRow = row
      },
      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
      showLocDetail(row) {
        this.dialogVisible = true
        this.detailSku = row.skuId
      },
    },

    filters: {},
  }
</script>

<style scoped lang="scss">
  .table-container {
    padding: 20px !important;

    & .title {
      display: flex;
      align-items: center;
    }

    & .detail-fields {
      .el-row {
        & .label {
          color: #282c34;
          width: 100px;
          text-align: right;
          display: inline-block;
          margin-right: 15px;
        }

        & .content {
          color: #000016;
        }
      }
    }
  }

  .detail-content {
    font-size: 16px !important;
    margin-top: 10px;
    font-weight: normal;
  }

  .header {
    font-size: 18px;
    color: #606266;
    //font-family: 'PingFang Bold';
  }

  .fs14 {
    font-size: 14px;
    font-family: 'PingFang Bold';
    color: #282c34;
  }

  .mr15 {
    margin-right: 15px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .pointer {
    cursor: pointer;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .loc-content {
    max-height: 300px;
    overflow: auto;
  }

  .left,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block;
    display: inline-block;
    border: 1px solid #909399;
    z-index: 1000;
    border-radius: 50%;
  }

  .el-icon-caret-right,
  .el-icon-caret-left {
    font-size: 36px;
    color: rgb(40, 130, 255);
  }

  .right {
    right: 20px;
  }

  .left {
    left: 7px;
  }

  .inline-block {
    display: inline-block;
  }
</style>
